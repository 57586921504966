import styled from "styled-components";

import { breakpoints } from "lib/breakpoints";
import { colors, shadow } from "lib/palette";

export const StyledModalDesktopHeader = styled.div`
    border-bottom: 1px solid ${colors.blue_3};
    height: 50px;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 13px 0 20px;
    position: relative;
    gap: 10px;

    @media (max-width: ${`${breakpoints.sm}px`}) {
        display: none;
    }

    &::after {
        content: "";
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 10px;
        left: 0;
        bottom: -10px;
        background: ${shadow.top};
    }

    @media (max-width: ${`${breakpoints.sm}px`}) {
        display: none;
    }
`;

export const StyledTitle = styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.black_6};
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 255px;
`;

export const StyledCloseButton = styled.button`
    height: max-content;
    width: max-content;
    background: transparent;
    display: flex;
    transition: 0.3s;
    transform: rotate(0deg);
    cursor: pointer;
    padding: 0;
    border: none;
    border-radius: 24px;
    margin-left: auto;
    min-width: 24px;

    &:hover {
        transform: rotate(-90deg);
        transition: 0.3s;
        color: ${colors.black_1};
    }
`;
