import styled from "styled-components";

import { colors, shadow } from "lib/palette";
import { breakpoints } from "lib/breakpoints";
import { zIndexes } from "lib/z-indexes";

export const StyledHeaderBlock = styled.div<{ withShadow: boolean }>`
    width: 100%;
    display: none;
    min-height: 56px;
    border-bottom: 1px solid ${colors.blue_3};
    position: relative;
    align-items: center;
    padding: 10px 0;

    &::after {
        content: "";
        display: ${({ withShadow }) => (withShadow ? "inline-block" : "none")};
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 10px;
        background: ${shadow.top};
        z-index: ${zIndexes.elementWithShadow};
    }

    @media (max-width: ${`${breakpoints.xl}px`}) {
        display: flex;
    }
`;
