import { z } from "zod";
import { TFunction } from "next-i18next";

import { IFormState } from "lib/types/form";
import { V1CreateUserResponse } from "lib/api/common/data-contracts";
import { UserType } from "lib/constants/user-type";
import {
    StringValidators,
    createStringValidation,
} from "lib/utils/validation/validators";

export type CreateUserFormProps = {
    onSuccess: (data?: V1CreateUserResponse) => void;
    setFormState: React.Dispatch<React.SetStateAction<IFormState>>;
    withSubRole?: boolean;
    companyId?: string;
    providerId?: string;
    userType: UserType;
};

export const testIds = {
    createUserFormComponent: "create-user-form-component",
    createUserFormEmailInput: "create-user-form-email-input",
    createUserFormRoleInput: "create-user-form-role-input",
    createUserFormRoleDropdown: "create-user-form-role-dropdown",
    createUserFormFirstNameInput: "create-user-form-first-name-input",
    createUserFormLastNameInput: "create-user-form-last-name-input",
    createUserFormHiddenSubmitButton: "create-user-form-hidden-submit-button",
    mandatory2faCheckbox: "mandatory-2fa-checkbox",
    roleDropdownOption: "role-dropdown-option",
};

export const MANDATORY_2FA_LABEL_KEY =
    "createUserForm.description.mandatory2fa";
export const NOT_UNIQUE_EMAIL_KEY = "createUserForm.description.notUniqueEmail";

export const USER_NOT_CREATED_ERROR_TOAST_KEY = "common:toast.userNotCreated";
export const USER_NOT_CREATED_ERROR_TOAST_MESSAGE =
    "Error occured. Registration link was not sent.";

export const generateSchema = (t: TFunction, withSubRole?: boolean) =>
    z.object({
        email: createStringValidation({
            validationRules: [{ type: StringValidators.EMAIL }],
            t,
        }),
        first_name: createStringValidation({
            validationRules: [
                { type: StringValidators.SPACES },
                {
                    type: StringValidators.AVAILABLE_SYMBOLS,
                    availableSymbols: ["s"],
                },
                { type: StringValidators.LENGTH_LIMIT, max: 255 },
            ],
            t,
        }),
        last_name: createStringValidation({
            validationRules: [
                { type: StringValidators.SPACES },
                {
                    type: StringValidators.AVAILABLE_SYMBOLS,
                    availableSymbols: ["s"],
                },
                { type: StringValidators.LENGTH_LIMIT, max: 255 },
            ],
            t,
        }),
        ...(withSubRole
            ? {
                  sub_role_id: createStringValidation({
                      validationRules: [],
                      t,
                  }),
              }
            : {}),
    });

export const EMPTY_VALUES = {
    email: "",
    first_name: "",
    last_name: "",
    role: "",
    sub_role_id: undefined,
    is_2fa_mandatory: true,
};
