import React from "react";
import { StyledHeaderBlock } from "./tablet-content-header.styled";

type Props = {
    children: React.ReactNode;
    withShadow?: boolean;
};

export const TabletContentHeader = ({ children, withShadow = true }: Props) => {
    return (
        <StyledHeaderBlock withShadow={withShadow}>
            {children}
        </StyledHeaderBlock>
    );
};
